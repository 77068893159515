import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import { css } from "@emotion/react"
import { useIntl } from "gatsby-plugin-react-intl"
import { device } from "../styles/device"
import { motion } from "framer-motion"

const PhotoGallerySection = () => {
    const intl = useIntl()

    const isMobile = () => {
        if (typeof navigator == `undefined`) return 3
        const num = navigator.userAgent.match(/iPhone|Android.+Mobile/) ? 1 : 3
        return num
    }

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: isMobile(),
        lazyLoad: true,
        autoplay: true,
        centerMode: true,
        centerPadding: "5%",
        speed: 800,
        // autoplaySpeed: 10000,
        // cssEase: "linear",
        pauseOnHover: false,
    }

    const data = useStaticQuery(graphql`
        query {
            allContentfulPhotoGallery {
                nodes {
                    photoGallery
                    galleryImages {
                        gatsbyImageData(quality: 50)
                    }
                }
            }
        }
    `)
    const content =
        intl.locale === "ja" ? data.allContentfulPhotoGallery.nodes[0] : data.allContentfulPhotoGallery.nodes[1]

    return (
        <>
            <h2 css={headline}>{content.photoGallery}</h2>
            <Slider {...settings} css={slider}>
                {content.galleryImages.slice(0, 6).map((asset, index) => (
                    <Link to="/gallery/" css={photoContainer} key={`gallery-${index}`}>
                        <motion.div
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 1.2, ease: [0.6, -0.05, 0.01, 0.9] },
                            }}
                            whileTap={{ scale: 1.08 }}
                            style={{ height: "100%" }}
                        >
                            <GatsbyImage
                                image={getImage(asset)}
                                key={`gallery-image-${index}`}
                                alt={`online shop image - ${index}`}
                                css={galleryImage}
                            />
                        </motion.div>
                    </Link>
                ))}
            </Slider>
        </>
    )
}
export default PhotoGallerySection

const headline = css`
    text-align: center;
    margin-bottom: 5vh;
    font-size: clamp(2.4rem, 2.4vw, 2.8rem);
`
const slider = css`
    width: 100%;
    height: min(80vh, 550px);
    margin-bottom: 20vh;
    overflow-x: hidden; //i do not know why
    .slick-dots {
        text-align: right;
        bottom: -50px;
    }
    .slick-slide {
        overflow: hidden;
        height: 100%;
    }
    .slick-list {
        height: 100%;
    }
    .slick-track {
        will-change: transform;
        height: 100%;
    }

    @media ${device.sp} {
        height: 78vmax;
    }
`
const galleryImage = css`
    width: 100%;
    height: 100%;
    @media ${device.sp} {
        width: 100%;
        height: 78vmax;
    }
`
const photoContainer = css`
    height: min(80vh, 550px);
    display: inline-block;
    overflow: hidden;

    @media ${device.sp} {
        height: 78vmax;
    }
`
